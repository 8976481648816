import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addProject, addProjects } from "../../redux/slices/projectsSlice";
const AddProject = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
   const dispatch = useDispatch();
  const [FormData, setFormData] = useState({
    title: "",
    brief: "",
    description: "",
    link: "",
    logo: "",
  })
// const [IMGTest, setIMGTest] = useState()
// const [Prev, setPrev] = useState()
  const clickHandler = async () => {
    if((!FormData.title) || (!FormData.title) || (!FormData.description)) {
        
    }else {
        // dispatch(addProject(FormData))
        await  dispatch(addProjects(FormData))
        await  window.location.reload(false);
        await setShow(false)
        // console.log(FormData);
        // setShow(false)
        // const reader = new FileReader();
        // reader.onloadend = () => {
        //   setPrev(reader.result)
        // }
        // reader.readAsDataURL(IMGTest)

        // console.log(FormData);
    }
  }
  useEffect(() => {

    // console.log(IMGTest);
  }, [])
  
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add a project
      </Button>
      {/* <img src={Prev} alt="" /> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Add title</Form.Label>
              <Form.Control
                type="text"
                placeholder="company"
                onChange={(e) =>
                  setFormData({ ...FormData, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Add brief</Form.Label>
              <Form.Control
                type="text"
                placeholder="brief"
                onChange={(e) =>
                    setFormData({ ...FormData, brief: e.target.value })
                  }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Add link</Form.Label>
              <Form.Control
                type="text"
                placeholder="link"
                onChange={(e) =>
                    setFormData({ ...FormData, link: e.target.value })
                  }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Add logo</Form.Label>
              <Form.Control
                type="text"
                placeholder="logo"
                onChange={(e) =>
                    setFormData({ ...FormData, logo: e.target.value })
                  }
              />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Add Test image</Form.Label>
              <Form.Control
                type="file"
                placeholder="logo"
                onChange={(e) =>
                  setIMGTest(e.target.files[0])
                  }
              />
            </Form.Group> */}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) =>
                    setFormData({ ...FormData, description: e.target.value })
                  }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={clickHandler} disabled={((!FormData.title) || (!FormData.link) || (!FormData.logo) || (!FormData.description) || (!FormData.title))}> */}
          <Button variant="primary" onClick={clickHandler} >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddProject;
