import React from 'react'
import './footer.css';
import {behance,email,fbFooter,instagram,linkedin,whatsapp} from '../../helper/imagesWorkedIn'
const Footer = () => {
  return (
    <footer class="footer">
    <div class="icons">
       <a href="https://www.facebook.com/people/Ahmed-Omar/100004129654142/?mibextid=ZbWKwL" target="_blank"><img src={fbFooter} alt="facebook" /></a>
       <a href="https://www.linkedin.com/in/ahmed-omar-783682201/" target="_blank"><img src={linkedin} alt="linkedin" /></a>
       <a href="https://www.instagram.com/ahmed.omar8958/" target="_blank"><img src={instagram} alt="instagram" /></a>
       <a href="https://www.behance.net/ahmedomar52" target="_blank"><img src={behance} alt="behance" /></a>
       <a href="mailto:ahmedomarabdallah1@gmail.com" target="_blank"><img src={email} alt="gmail" /></a>
       <a href="https://wa.link/z495l6" target="_blank"><img src={whatsapp} alt="whatsapp" /></a>

        <p class="company-name">
            Elomar &copy; 2021, ALL Rights Reserved
        </p>
    </div>
</footer>
  )
}

export default Footer