
import React,{useState,useEffect} from 'react'
import { onAuthStateChanged ,signOut  } from "firebase/auth";
import './App.css';
import { useSelector, useDispatch } from 'react-redux'
import {Menu,Header, Experence,WorkedIn,ContactUs,Projects,ProjectsOwn,Footer,Social,About}  from './components/index'
import { auth } from './helper/firebaseConfig';
import AOS from "aos";
import "aos/dist/aos.css";
import Scrollspy from 'react-scrollspy'

/*
to clear warning from Scrollspy
*/
// for disable anything show on console
console.log = console.warn = console.error = () => {};
// Look ma, no error!
console.error('Something bad happened.');

function App() {
  const count = useSelector((state) => state.counter.value)
  const [curruntUser, setCurruntUser] = useState(null)
  useEffect(() => {
        // aos animation 
        AOS.init();
        AOS.refresh();
    const unAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setCurruntUser(user)
        // ...
      } else {
        setCurruntUser(null)
      }
    })

  }, [])
  return (
    <div  className="parentSections">
    
      <Menu curruntUser={curruntUser}  />
      <Header />
      <About />
      <Experence curruntUser={curruntUser}/>
      <WorkedIn />
      <Social />
      <Projects  curruntUser={curruntUser} />
      <ProjectsOwn curruntUser={curruntUser} />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;


