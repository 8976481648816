import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {addExpe,postData} from '../../redux/slices/experienceSlice'
const AddExperence = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
   const dispatch = useDispatch();
  const [FormData, setFormData] = useState({
    // id: new Date().getTime(),
    nameCompany: "",
    date: "",
    description: "",
  })

  const clickHandler = async () => {
    if((!FormData.nameCompany) || (!FormData.date) || (!FormData.description)) {
        
    }else {
        // dispatch(addExpe(FormData))
        // console.log(FormData);
        await dispatch(postData(FormData))
       await  window.location.reload(false);
       await setShow(false)

    }
  }
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add a Experience
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Add Company</Form.Label>
              <Form.Control
                type="text"
                placeholder="company"
                onChange={(e) =>
                  setFormData({ ...FormData, nameCompany: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Add Date</Form.Label>
              <Form.Control
                type="text"
                placeholder="Date"
                onChange={(e) =>
                    setFormData({ ...FormData, date: e.target.value })
                  }
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) =>
                    setFormData({ ...FormData, description: e.target.value })
                  }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={clickHandler} disabled={((!FormData.date) || (!FormData.description) || (!FormData.nameCompany))}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddExperence;
