import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {editExpe} from '../../redux/slices/experienceSlice'
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../helper/firebaseConfig";

const EditExprerence = ({EditData,setEditModal}) => {
    const [show, setShow] = useState(true);
    const handleClose = () => {
        setShow(false)
        setEditModal(false)
    };

     const dispatch = useDispatch();
    const [FormData, setFormData] = useState({
      id: EditData.id,
      nameCompany: EditData.nameCompany,
      date: EditData.date,
      description: EditData.description,
    })
    const clickHandler = async () => {
        if((!FormData.nameCompany) || (!FormData.date) || (!FormData.description)) {
            
        }else {
          console.log(EditData.id);
          const userDoc = await doc(db,'experience',FormData.id)
          await updateDoc(userDoc,{
            nameCompany: FormData.nameCompany,
            date: FormData.date,
            description: FormData.description,
          })
          await  dispatch(editExpe(FormData))
            setShow(false)
        }
      }

  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Add Company</Form.Label>
              <Form.Control
                type="text"
                placeholder="company"
                value={FormData.nameCompany}
                onChange={(e) =>
                  setFormData({ ...FormData, nameCompany: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Add Date</Form.Label>
              <Form.Control
                type="text"
                placeholder="Date"
                value={FormData.date}
                onChange={(e) =>
                    setFormData({ ...FormData, date: e.target.value })
                  }
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={FormData.description}
                rows={3}
                onChange={(e) =>
                    setFormData({ ...FormData, description: e.target.value })
                  }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={clickHandler} disabled={((!FormData.date) || (!FormData.description) || (!FormData.nameCompany))}>
            save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditExprerence