import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {collection,getDocs,addDoc,onSnapshot ,doc,deleteDoc} from 'firebase/firestore'
import { db } from '../../helper/firebaseConfig'


export const getProjects = createAsyncThunk('projectsSection/getProject',async (_,thunkAPI)=>{
  const {rejectWithValue} = thunkAPI;
  try {
    const collectionRef = await collection(db,"deliveredDrojects")
    const data = await getDocs(collectionRef)
    const dataRes = data.docs.map((doc) =>  ({...doc.data(),id:doc.id}))
    // console.log(dataRes);
   return dataRes
  } catch (error) {
    return rejectWithValue(error.massage)
  }
})  


export const addProjects = createAsyncThunk('projectsSection/addProject',async (proj,thunkAPI)=> {
  const {rejectWithValue} = thunkAPI;
  try {
    // console.log(proj);
    const collectionRef = await collection(db,"deliveredDrojects")
    const data = await addDoc(collectionRef,proj)
    // console.log(data);
    return data
  } catch (error) {
    return rejectWithValue(error.massage)
  }
})


const initialState = {
  // projectsApi:[
  //     {id:1,title:"ozcar",brief:"selling cars",description:"will be a long description",link:"go.com",
  //     logo:"https://www.ozcar.com.au/webroot/filebrowser/upload/images/Ozcar_Logo.jpg",
  //     imgDesk:['https://www.ozcar.com.au/webroot/filebrowser/upload/images/OZCAR0990_OzClub_Centrebanners-06.png'],
  //     imgDesk:['https://www.ozcar.com.au/webroot/filebrowser/upload/images/Ozcar_Logo.jpg']},
      
  //     {id:2,title:"homeblindsaustralia",brief:"Beautiful window furnishings ",description:"will be a long description",
  //     link:"https://homeblindsaustralia.com.au/",
  //     logo:"https://homeblindsaustralia.com.au/css/images/New_images/HBA-Logo.png",
  //     imgDesk:['https://homeblindsaustralia.com.au/files/images/baa0d_Inspiration_gallery_for_blinds_shutters.jpg'],
  //     imgMob:['https://homeblindsaustralia.com.au/css/images/New_images/HBA-Logo.png']}

  // ]
  projectsApi:[]
}

export const projectsSlice = createSlice({
  name: 'projectsSection',
  initialState,
  reducers: {
    addProject : (state,action) => {
      // state.projectsApi.push(action.payload)
   },
   editProject2: (state,action) => {
    // console.log(state,action);
   state.projectsApi.map((item)=> {
    if(item.id == action.payload.id) {
      item.logo = action.payload.logo
      item.description = action.payload.description
      item.title = action.payload.title
      item.link = action.payload.link
      item.brief = action.payload.brief
      item.imgDesk = action.payload.imgDesk
    }
   })
  },
    deleteProject:(state,action) => {
      // console.log(action.payload);
      state.projectsApi = state.projectsApi.filter((proj)=>  proj.id !== action.payload )
    }
  },
  extraReducers: {
    //get projects
    [getProjects.pending]: (state) => {
      state.projectsApi = []
    },
    [getProjects.fulfilled]: (state, { payload }) => {
      state.projectsApi = payload;
    },
    [getProjects.rejected]: (state,{payload}) => {
      state.projectsApi = payload
    },
    //add projects
    [addProjects.pending]: (state) => {
      // state.projectsApi = state.projectsApi
    },
    [addProjects.fulfilled]: (state, { payload }) => {
      console.log(payload);
      console.log(state.projectsApi);
      // state.projectsApi = state.projectsApi.push(payload);
    },
    [addProjects.rejected]: (state,{payload}) => {
      // state.projectsApi = state.projectsApi
    },
  }
})

// Action creators are generated for each case reducer function
export const { deleteProject, addProject, editProject2 } = projectsSlice.actions

export default projectsSlice.reducer