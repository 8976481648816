import { configureStore } from '@reduxjs/toolkit'
import  counterSlice  from './slices/countSlice'
import experienceSlice from './slices/experienceSlice'
import  projectsSlice  from './slices/projectsSlice'
import  ownProjectSlice  from './slices/ownProjectSlice'


export const store = configureStore({
  reducer: {
      counter : counterSlice,
      experienceReducer : experienceSlice,
      projectsReduce : projectsSlice,
      ownProjectSlice : ownProjectSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})