import React from "react";
import ao from "../../assets/imgs/aoTrans.png";
import "./About.css";
const About = () => {
  return (
    <div className="about" id="About">
      <div className="container">
        <div className="left">
          <h1>Ahmed Omar</h1>
          <p>
            I'm an experience UI developer with more than 4+ years of
            experience, And always try to enhance myself by learning new things
            & practice by making a project to apply what I have.<br />  
          </p>
          <h4> My areas of expertise are as follows:</h4>
          <ul className="areas">
            <li>
              Translate mockups from graphic UI files in different formats
              (Figma, Zeplin, etc) to web pages
            </li>
            <li>
              Build responsive website which works across browsers and devices
            </li>
            <li>
              Build modern web application using advanced UI frameworks
              like React, Vue
            </li>
            <li>Integrate front-end web application with back-end API</li>

            <li>Optimize app performance</li>
            <li>
              Good understanding of Content Management UI development like
              “SharePoint”
            </li>
            <li>
              Hand-code responsive HTML emails, email templates, and landing
              pages
            </li>
            <li>Responsive website development</li>
          </ul>

        </div>
        <div className="right">
          <img src={ao} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
