import React from 'react'
import './Social.css';
import {whatsappF,linkedinF,instagramF,behanceF} from '../../helper/imagesWorkedIn'
const Social = () => {

    
  return (
    <div className='Social' data-aos="fade-right">
    
       <a data-aos="fade-up" href="https://www.linkedin.com/in/ahmed-omar-783682201/" target="_blank"><img src={linkedinF} alt="linkedin" /></a>
       <a data-aos="fade-up" href="https://www.instagram.com/ahmed.omar8958/" target="_blank"><img src={instagramF} alt="instagram" /></a>
       {/* <a href="https://www.behance.net/ahmedomar52" target="_blank"><img src={behanceF} alt="behance" /></a>> */}
       <a data-aos="fade-up" href="https://wa.link/z495l6" target="_blank"><img src={whatsappF} alt="whatsapp" /></a>
    </div>
  )
}

export default Social