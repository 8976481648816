import React from "react";
import "./header.css";
import Button from "react-bootstrap/Button";
import htmlcss from "../../assets/imgs/htmlCss.svg";
import cv from "../../assets/Ahmed-CV.pdf";
const Header = () => {
  return (
    <section className="headerBg" id="home">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>
              <span data-aos="fade-up"> Hello.</span>
              <br />
              <span data-aos="fade-up" data-aos-delay="200">
                I’m a freelance user
              </span>
              <span data-aos="fade-up" data-aos-delay="300">
                interface developer
              </span>
              {/* I love to create <br /> beautiful and <br /> efficient websites */}
              {/* 
                 I'm a Front-End Developer for ChowNow in Los Angeles, CA.
I have serious passion for UI effects, animations and creating intuitive, dynamic user experiences.
Let's make something special.
                 */}
            </h2>
            <p data-aos="zoom-in">
              My name’s Ahmed Omar. I craft user interfaces using <br />
              modern frontend web technologies.
            </p>
            {/* <Button variant="dark ">Danger</Button> */}
            {/* <button className='animationClick' data-aos="zoom-in">Danger</button> */}
            <a className="animationClick" data-aos="zoom-in" href={cv} download>
             Download CV
            </a>
          </div>
          <div className="col-md-6">
            {/* <img src={htmlcss} alt="logo" data-aos="fade-left" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              width="1015.12774"
              height="526"
              viewBox="0 0 1015.12774 526"
            >
              <title>html</title>
              <rect 
                x="600.64726"
                y="143.87206"
                width="300.3462"
                height="532.4319"
                transform="translate(59.46229 833.98563) rotate(-74.20991)"
                fill="#3f3d56"
              />
              <circle cx="451.92875" id="circle1" cy="18.22867" r="4.24322" fill="#6c63ff" />
              <circle cx="464.99468" id="circle2" cy="21.92352" r="4.24322" fill="#6c63ff" />
              <circle cx="478.06061" id="circle3" cy="25.61836" r="4.24322" fill="#6c63ff" />
              <path
                d="M807.41965,376.52959a49.57145,49.57145,0,0,0-20.70373.48865,49.483,49.483,0,0,0,1.46357-20.91986,49.19363,49.19363,0,0,0-18.50638,11.94255,49.193,49.193,0,0,0-9.51152-19.86558,49.48244,49.48244,0,0,0-9.70867,18.58827A49.57169,49.57169,0,0,0,733.07115,355.505s-3.09015,15.716,4.34937,28.61187c6.62151,11.47786,19.34349,16.84908,22.06311,17.8924l.06371.07642.03418-.03883c.276.10469.43054.15809.43054.15809s.05845-.30187.139-.84058c.04889-.0604.09834-.12183.15239-.18961.01056.086.02049.16416.03051.24122-.21353.50116-.32189.789-.32189.789s.15966.0354.44964.09076l.00877.051.09429-.03174c2.8634.53547,16.51408,2.622,28.16579-3.68844C801.82182,391.5365,807.41965,376.52959,807.41965,376.52959Z"
                transform="translate(-92.43613 -187)"
                fill="#6c63ff"
              />
              <circle id="circle"
                cx="689.59221"
                cy="138.35165"
                r="11.03237"
                fill="#6c63ff"
              />
              <rect
                x="581.48421"
                y="421.57144"
                width="344.54948"
                height="7.4962"
                transform="translate(51.74221 -376.0588) rotate(15.79009)"
                fill="#6c63ff"
              />
              <rect
                x="600.67236"
                y="426.50275"
                width="59.40508"
                height="59.40508"
                transform="translate(55.49076 -341.31883) rotate(15.79009)"
                fill="#6c63ff"
              />
              <rect
                x="706.83303"
                y="456.52336"
                width="59.40508"
                height="59.40508"
                transform="translate(67.66575 -369.07379) rotate(15.79009)"
                fill="#6c63ff"
              />
              <rect
                x="812.9937"
                y="486.54396"
                width="59.40508"
                height="59.40508"
                transform="translate(79.84073 -396.82874) rotate(15.79009)"
                fill="#6c63ff"
              />
              <rect
                x="596.30192"
                y="496.12363"
                width="44.12949"
                height="5.09186"
                transform="translate(66.59275 -336.44884) rotate(15.79009)"
                fill="#6c63ff"
              />
              <rect
                x="702.46259"
                y="526.14423"
                width="44.12949"
                height="5.09186"
                transform="translate(78.76773 -364.20379) rotate(15.79009)"
                fill="#6c63ff"
              />
              <rect
                x="808.62326"
                y="556.16484"
                width="44.12949"
                height="5.09186"
                transform="translate(90.94272 -391.95875) rotate(15.79009)"
                fill="#6c63ff"
              />
              <ellipse
                cx="289.60018"
                cy="396.5"
                rx="284"
                ry="10.5"
                fill="#d0cde1"
              />
              <ellipse
                cx="794.12774"
                cy="515.5"
                rx="221"
                ry="10.5"
                fill="#d0cde1"
              />
              <path id="css"
                d="M778.1721,621H841.93v26.66361H804.83571v26.6636h37.09761v26.662H778.1721Zm76.51116,0h63.75958v23.1856h-37.096v4.63679h37.096v53.32722H854.68491V677.80192h37.096v-4.63679H854.68161V621Zm76.51281,0h63.75958v23.1856h-37.096v4.63679h37.096v53.32722H931.19607V677.80192h37.096v-4.63679H931.19773V621Z"
                transform="translate(-92.43613 -187)"
                fill="#3f3d56"
              />
              <path id="html"
                d="M220.92612,499h26.30227v25.98626h24.061V499h26.30389v78.69291H271.291V551.342H247.23v26.3509H220.92774V499Zm111.25393,26.09646H309.02821V499h72.6254v26.09646H358.48719v52.59645H332.18492V525.09646h-.00487ZM393.17929,499h27.42535l16.87042,27.65061L454.32925,499h27.43668v78.69291H455.57061V538.6884L437.475,566.668h-.45376L418.91436,538.6884v39.00451H393.17929Zm101.67621,0h26.31038v52.68234h36.9885v26.01057H494.85389V499Z"
                transform="translate(-92.43613 -187)"
                fill="#3f3d56"
              />
              <polygon
                points="82.034 366.091 79.876 390.558 97.146 389.118 97.146 368.25 82.034 366.091"
                fill="#ffb8b8"
              />
              <polygon
                points="66.203 366.091 68.362 390.558 51.092 389.118 51.092 368.25 66.203 366.091"
                fill="#ffb8b8"
              />
              <path
                d="M203.9741,365.27567s5.7568,34.54079,5.7568,39.578-2.1588,37.41919-5.7568,43.89559-4.3176,9.3548-2.8784,12.2332.7196,2.15879,0,4.31759-2.1588,1.4392,0,4.3176a7.56062,7.56062,0,0,1,0,8.6352c-1.4392,2.1588,2.8784,60.44639-2.8784,64.764s-7.196,18.70959-7.196,18.70959l-18.70959-2.1588v-15.8312s-4.3176-11.51359-1.4392-18.70959,4.3176-45.33479,4.3176-45.33479-.7196-3.598,0-5.7568,0-3.598-1.4392-4.3176-2.1588-3.598-2.1588-3.598l-2.1588-52.53078L160.79811,465.3s8.6352,70.52079,0,79.87558v16.5508H142.08852l2.8784-17.99s-5.7568-16.55079-2.8784-20.14879-8.6352-61.166-7.196-63.32478.7196-2.8784,0-4.3176-2.8784-2.8784-2.1588-4.3176,0-4.3176,0-4.3176-13.6724-67.64238-7.196-74.11878S203.9741,365.27567,203.9741,365.27567Z"
                transform="translate(-92.43613 -187)"
                fill="#2f2e41"
              />
              <path
                d="M176.62931,568.20282s1.4392-6.90136,12.9528-4.53008c0,0,2.87839,5.96928,2.87839,8.84768s2.8784,22.30759,2.8784,22.30759,11.5136,19.4292-2.8784,19.4292-19.42919-5.0372-20.14879-10.0744.7196-6.4764-2.1588-8.6352-4.3176-2.1588-3.598-5.0372,2.1588-17.27039,2.1588-17.27039.7196-10.794,4.6774-7.9156l3.9578,2.8784"
                transform="translate(-92.43613 -187)"
                fill="#2f2e41"
              />
              <path
                d="M156.48051,568.20282s-1.4392-6.90136-12.95279-4.53008c0,0-2.8784,5.96928-2.8784,8.84768s-2.8784,22.30759-2.8784,22.30759-11.5136,19.4292,2.8784,19.4292,19.42919-5.0372,20.14879-10.0744-.7196-6.4764,2.1588-8.6352,4.3176-2.1588,3.598-5.0372-2.1588-17.27039-2.1588-17.27039-.7196-10.794-4.6774-7.9156l-3.9578,2.8784"
                transform="translate(-92.43613 -187)"
                fill="#2f2e41"
              />
              <circle cx="84.91278" cy="26.44011" r="19.4292" fill="#ffb8b8" />
              <path
                d="M161.51771,216.31851s-7.9156,30.94279-9.3548,31.66239,33.1016,3.598,33.1016,3.598-8.6352-26.6252,0-30.94279Z"
                transform="translate(-92.43613 -187)"
                fill="#ffb8b8"
              />
              <path
                d="M156.46253,235.17488l-.00093-.004L132.73372,251.5789l4.3176,128.80837s31.66239-3.598,38.85839-2.8784,30.22319-6.4764,30.22319-6.4764L196.04659,249.9967a9.94192,9.94192,0,0,0-9.20821-9.09165l-4.32176-.30476s-4.31728,10.68931-17.30682,5.17465A15.15623,15.15623,0,0,1,156.46253,235.17488Z"
                transform="translate(-92.43613 -187)"
                fill="#d0cde1"
              />
              <path
                d="M157.03631,234l-29.33979,13.9809-5.7568,82.754s-11.5136,49.65239-2.1588,64.04438,25.186,15.8312,25.186,15.8312,20.86839-82.754,15.11159-110.81837S157.03631,234,157.03631,234Z"
                transform="translate(-92.43613 -187)"
                fill="#575a89"
              />
              <path
                d="M101.79093,358.79927v10.794s-4.3176,23.74679,9.35479,23.02719,7.196-25.90559,7.196-25.90559l-4.3176-7.9156Z"
                transform="translate(-92.43613 -187)"
                fill="#ffb8b8"
              />
              <path
                d="M228.4405,380.38727l-1.4392,12.23319s7.196,24.4664-6.4764,25.186-3.748-25.03584-3.748-25.03584l4.46756-13.82255Z"
                transform="translate(-92.43613 -187)"
                fill="#ffb8b8"
              />
              <path
                d="M182.08361,238.08539h0a84.74684,84.74684,0,0,1,35.43844,18.42216l.12443.10854-10.0744,53.25038-1.4392,19.4292s26.62519,94.98717-4.3176,81.31478c0,0-26.62519-82.754-23.02719-120.89277S182.08361,238.08539,182.08361,238.08539Z"
                transform="translate(-92.43613 -187)"
                fill="#575a89"
              />
              <path
                d="M208.2917,255.8965c.7196-.7196,9.3548,1,9.3548,1s15.83119,55.84838,15.83119,65.20318,2.1588,62.60518,2.1588,62.60518l-18.99514,1.91474-15.37506-73.98644Z"
                transform="translate(-92.43613 -187)"
                fill="#575a89"
              />
              <path
                d="M132.73372,247.9809h-5.0372s-14.392,5.7568-15.1116,12.2332-20.14879,71.96-20.14879,71.96l5.0372,33.10159,18.70959-2.8784,3.598-36.69959,18.7096-40.29759Z"
                transform="translate(-92.43613 -187)"
                fill="#575a89"
              />
              <path
                d="M173.57432,218.8626l.74492-5.19289c.17362-1.21036.882-2.8018,2.05958-2.47264,1.00159.28,3.18376,1.13738,3.5491.87857,1.3682-.96923,3.43868.72552,4.87744-.13549.6028-.36073.996-1.13357,1.69662-1.18516a1.9326,1.9326,0,0,1,1.24551.56426,8.47721,8.47721,0,0,0,3.22412,1.75148,3.468,3.468,0,0,0,3.39423-.92517,1.80257,1.80257,0,0,1,.60022-.57537,1.34659,1.34659,0,0,1,.86308.03969l3.67611,1.00088a14.12556,14.12556,0,0,0-1.88988-11.62984,23.39121,23.39121,0,0,0-5.35422-5.24365q-3.41485-2.59788-7.04825-4.887a22.89209,22.89209,0,0,0-8.563-3.72539c-3.1-.47075-6.57114.37147-8.52107,2.827a5.48684,5.48684,0,0,1-1.97362-2.66617,2.01963,2.01963,0,0,0,.1303,3.09667l-3.109-1.98535c-.874.91767.83358,2.5817.08892,3.60711a1.89976,1.89976,0,0,1-.837.54649,14.25211,14.25211,0,0,0-4.55865,2.86924,17.01032,17.01032,0,0,0-3.59023,6.61357c-1.85261,5.31494-3.19909,11.41016-.56689,16.38533.29841.564,4.23741,9.87353,4.76219,10.23655,1.774,1.22715.61919-1.74269,1.89034,0,.9968,1.36657.81021-3.86379,2.29164-2.71572a3.693,3.693,0,0,0,4.01967.18077c.61419-.42594.41628-2.79122.08489-4.96329a2.55893,2.55893,0,0,1,3.11332-2.87895C171.7198,218.71218,173.5427,219.08306,173.57432,218.8626Z"
                transform="translate(-92.43613 -187)"
                fill="#2f2e41"
              />
              <polygon
                points="117.655 134.02 125.57 199.504 120.533 199.504 117.655 134.02"
                opacity="0.2"
              />
              <polygon
                points="34.181 108.834 30.179 134.02 37.779 108.834 35.62 108.834 34.181 108.834"
                opacity="0.2"
              />
            </svg>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
