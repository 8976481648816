import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {collection,getDocs,addDoc,onSnapshot ,doc,deleteDoc} from 'firebase/firestore'
import { db } from '../../helper/firebaseConfig'



export const getData = createAsyncThunk("experienceSection/getData",async () => {
  try {
    const collectionRef = await collection(db,"experience")
    const data = await getDocs(collectionRef)
   const datare = data.docs.map((doc) =>  ({...doc.data(),id:doc.id}))
    
   return datare



  //  const sc = onSnapshot(collectionRef,(snapshot)=> {
  //   return snapshot.docs.map((doc) =>  (console.log(doc.data())))
  //   })

  } catch (error) {
    console.log(`${error} from experienceSection/getData`);
  }
})


export const postData = createAsyncThunk("experienceSection/postData",async (postExp,{rejectWithValue}) => {
  try {
    const collectionRef = await collection(db,"experience")
    const data = await addDoc(collectionRef,postExp)

  //  return data
  
  } catch (error) {
    console.log(`${error} from experienceSection/postData`);
    return rejectWithValue(error)
  }
})



// const initialState = {
//     experience: [
//         { "id": 1, "nameCompany": "selftress","date":" JUN 2018 – Present", "description": "Translated UX wireframes and mockups into responsive, interactive features, using HTML/CSS and JavaScript." },
//         { "id": 2, "nameCompany": "selftress2","date":" JUN 2018 – Present", "description": "Translated UX wireframes and mockups into responsive, interactive features, using HTML/CSS and JavaScript." }
//       ],
// }

export const experienceSlice = createSlice({
  name: 'experienceSection',
  initialState:{
    experience:[],
    stateOf: null
  },
  reducers: {

    addExpe : (state,action) => {
        console.log(action);
        // state.experience.push(action.payload)

    },
    deleteExpe: (state,action)=> {
      state.experience = state.experience.filter((item)=> item.id !== action.payload)
    },
    editExpe: (state,action) => {
      // console.log(state,action);
     state.experience.map((item)=> {
      if(item.id == action.payload.id) {
        item.nameCompany = action.payload.nameCompany
        item.description = action.payload.description
        item.date = action.payload.date
      }
     })
    }
  },
  extraReducers: {
    [getData.pending]: (state, {payload}) => {
      state.stateOf = "loading"
    },
    [getData.fulfilled]: (state, {payload}) => {
      state.experience = payload
    },
    [getData.rejected]: (state, {payload}) => {
      state.stateOf = "rejected"
    },
    // post req
    [postData.pending]: (state, {payload}) => {
      state.stateOf = "loading"
    },
    [postData.fulfilled]: (state, {payload}) => {
      // state.experience = payload;
      // console.log(payload);
    },
    [postData.rejected]: (state, {payload}) => {
      state.stateOf = "rejected"
    },


  }




})

// Action creators are generated for each case reducer function
export const { addExpe, deleteExpe, editExpe } = experienceSlice.actions

export default experienceSlice.reducer