import React,{useState,useEffect} from 'react'
import { Button, Modal } from "react-bootstrap";
import {signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../helper/firebaseConfig';
import { onAuthStateChanged ,signOut  } from "firebase/auth";



const SingIn = ({curruntUser}) => {
    // console.log(curruntUser);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [email, setEmail] = useState(null)
    const [password, setPasswoed] = useState(null)

    

    const [error, setEror] = useState({
        code : "",
        message : ""
    })
    const onSubmitHandler = async (e) => {
        e.preventDefault()
        // test@test.com 123456789
        try {
          await signInWithEmailAndPassword(auth,email, password)
          console.log('yes');
         setShow(false)
        } catch (error) {
            
            console.log( error.code);
            setEror({...error,code:error.code})
            setEror({...error,message:error.message})
            console.log( error.message);
        }
    }

    const sinOutFun = async () => {
        await signOut(auth)
    }



    return (
        <>
          
          {
              curruntUser === null ? <Button variant="primary" onClick={handleShow}>login </Button> : <Button variant="primary" onClick={sinOutFun}>
              signOut
              </Button>

          }
          
          {/* onHide={handleClose} */}
          <Modal show={show} onHide={handleClose} className="loginModal">
            <Modal.Header closeButton>
              <Modal.Title>login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={onSubmitHandler}>
                    <input type="email" name={email} placeholder='email' onChange={(e)=> setEmail(e.target.value)} />
                    <input type="password" name={password} placeholder='password' onChange={(e)=> setPasswoed(e.target.value)} />
                    <Button type='submit' variant="primary">
                        login
                    </Button>
                    {
                        error.code
                    }
                </form>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
          </Modal>
        </>
      );
}

export default SingIn