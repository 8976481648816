import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { editProject2 } from "../../redux/slices/ownProjectSlice";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../helper/firebaseConfig";

const EditProject = ({EditData,setEditModal}) => {
    const [show, setShow] = useState(true);
    const handleClose = () => {
        setShow(false)
        setEditModal(false)
    };

     const dispatch = useDispatch();
    const [FormData, setFormData] = useState({
      id: EditData.id,
      title: EditData.title,
      description: EditData.description,
      brief: EditData.brief,
      link: EditData.link,
      logo: EditData.logo,


    })
    const clickHandler = async () => {
      console.log(FormData);
        if((!FormData.title) || (!FormData.brief) || (!FormData.description)) {
            
        }else {

          console.log(EditData.id);
          const userDoc = await doc(db,'ownProject',FormData.id)
          await updateDoc(userDoc,{
            title: FormData.title,
            brief: FormData.brief,
            description: FormData.description,
            link : FormData.link,
            logo: FormData.logo,
          })
          await  dispatch(editProject2(FormData))
            setShow(false)

          
            // dispatch(editProject2(FormData))
            // setShow(false)
            // console.log(FormData);
        }
      }

  return (
    <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Add title</Form.Label>
              <Form.Control
                type="text"
                placeholder="company"
                value={FormData.title}
                onChange={(e) =>
                  setFormData({ ...FormData, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Add brief</Form.Label>
              <Form.Control
                type="text"
                placeholder="Date"
                value={FormData.brief}
                onChange={(e) =>
                    setFormData({ ...FormData, brief: e.target.value })
                  }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Add link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Date"
                value={FormData.link}
                onChange={(e) =>
                    setFormData({ ...FormData, link: e.target.value })
                  }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Add logo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Date"
                value={FormData.logo}
                onChange={(e) =>
                    setFormData({ ...FormData, logo: e.target.value })
                  }
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={FormData.description}
                rows={3}
                onChange={(e) =>
                    setFormData({ ...FormData, description: e.target.value })
                  }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={clickHandler} disabled={((!FormData.title) || (!FormData.description) || (!FormData.brief))}>
            save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditProject