import React from "react";
import './menu.css';
import aoWhite from '../../assets/imgs/test.png'
import { Navbar, Container, Nav } from "react-bootstrap";

import SingIn from "./SingIn";
import Scrollspy from 'react-scrollspy'

const Menu = ({curruntUser}) => {




  return (
    // className="fixed-top"
//     <div>
// <Scrollspy items={ ['Experience', 'section-2', 'section-3'] } currentClassName="is-current">
//   <li><a href="#section-1">section 1</a></li>
//   <li><a href="#section-2">section 2</a></li>
//   <li><a href="#section-3">section 3</a></li>
// </Scrollspy>
//     </div>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark"  className="fixed-top"  >
      <Container>
        <Navbar.Brand href="#">
          <img src={aoWhite} alt="AO" width="100" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Scrollspy items={ ['home','About','Experience', 'Projects', 'ownProjects','Contact'] } currentClassName="is-current">
            {/* <Nav.Link href="#about">About</Nav.Link> */}
            <a className="nav-link" href="#">Home</a>
            <Nav.Link href="#About">About</Nav.Link>
            <Nav.Link href="#Experience">Experience</Nav.Link>
            {/* <Nav.Link href="#workedInId">Worked in</Nav.Link> */}
            <Nav.Link href="#Projects">projects</Nav.Link>
            <Nav.Link href="#ownProjects">React-Vue </Nav.Link>
            <Nav.Link href="#Contact">Contact us</Nav.Link>
            </Scrollspy>

          </Nav>
          <Nav>
            {/* <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link> */}
            <SingIn curruntUser={curruntUser} />
          </Nav>
        </Navbar.Collapse>
         
      </Container>
    </Navbar>

  );
};

export default Menu;
