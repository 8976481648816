import React from "react";
import {
  Ozcar_Logo,
  bhf_couriers,
  taskat,
  bigRed,
  easy,
  HBA_Logo,
  iti,
  afra,
  vmoto_logo,
  homeBilnd,
  gadarat,
  safe,
  ministryCult,
  tfc,
  hayqa,
  saso,
  gaftStstem,
  gami,
  IDSC,
  Emdad,
  mewa,
  tamyoz,
  awqaf,
  tnis,
} from "../../helper/imagesWorkedIn";
import "./workedIn.css";
const WorkedIn = () => {
  return (
    <div>
      
      <div className="container" id="workedInId">
        <div className="row">
           <h2>I'm happy to work</h2>
          <div className="images" data-aos="zoom-in">
            <img src={Ozcar_Logo} alt="Australian Leading Used Car Dealership" title="Australian Leading Used Car Dealership" />
            <img src={bhf_couriers} alt=" Australian transport services from VIP Couriers to Interstate Couriers" title=" Australian transport services from VIP Couriers to Interstate Couriers"/>
            <img src={saso} alt="government body that coordinates standardization activities and facilitates the development of Saudi Standard" title="government body that coordinates standardization activities and facilitates the development of Saudi Standard" />
            <img src={gami} alt="General Authority for Military Industries System" title="General Authority for Military Industries System"/>
            <img src={safe} alt="National Security Services in sa" title="National Security Services in SA" />
            <img src={hayqa} alt=" General Authority of Foreign Trade (GAFT) " title=" General Authority of Foreign Trade (GAFT) " />
            <img src={ministryCult} alt="Ministry of Culture" title="Ministry of Culture"/>
            <img src={iti} alt="ITI is a privately-owned Australian company"  title="ITI is a privately-owned Australian company"/>
            <img src={vmoto_logo} alt="online marketplace " title="online marketplace " />
            <img src={bigRed} alt="" />
            <img src={HBA_Logo} alt="window shutters online"  title="window shutters online"/>
            <img src={taskat} alt="" />
            <img src={tfc} alt="International Islamic Trade Finance" title="International Islamic Trade Finance"/>
            <img src={afra} alt="" />
            {/* <img src={homeBilnd} alt="" /> */}
            <img src={gadarat} alt="" />
            <img src={easy} alt="templates website" title="templates website"/>
            <img src={gaftStstem} alt="General Authority of Foreign Trade System" title="General Authority of Foreign Trade System"/>
            <img src={Emdad} alt="Emdad to recruit human cadres" title="Emdad to recruit human cadres" />
            <img src={IDSC} alt="" />
            <img src={mewa} alt="Ministry of Environment, Water and Agriculture" title="Ministry of Environment, Water and Agriculture"/>
            <img src={tamyoz} alt="A leading company in providing technical and vocational training services" title="A leading company in providing technical and vocational training services" />
            <img src={awqaf} alt="General Authority for Awqaf" title="General Authority for Awqaf" />
            <img src={tnis} alt="National Company of Telecommunications and Information Security System" title="National Company of Telecommunications and Information Security System" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkedIn;
