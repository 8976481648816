import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import logo from "../../assets/imgs/workedIn/Ozcar_Logo.jpg";
import {deleteProject, getProjects} from '../../redux/slices/projectsSlice'
import AddProject from "./AddProject";
import EditProject from "./EditProject";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../helper/firebaseConfig";
import "./Projects.css";


const Projects = ({curruntUser}) => {
  const projects = useSelector((state) => state.projectsReduce.projectsApi);
  const dispatch = useDispatch()
  const [EditModal, setEditModal] = useState(false)
  const [EditData, setEditData] = useState({})


  const handlerEdit = (data) => {
    setEditModal(prv => !prv)
    setEditData(data)
  }
  // console.log(projects);
  const deleteProjectFun = async (id) => {
    await deleteDoc(doc(db, "deliveredDrojects", id));
    await dispatch(deleteProject(id))
  }
  useEffect(() => {
    dispatch(getProjects())
  }, [])
  

  return (
    <div className="projectParent " id="Projects">
      {/* <div className='scrollingTop' id=""></div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>Projects </h2>
            <span className="hint">Use VPN if links doesn't open</span>
            {
               curruntUser !== null && <AddProject />
            }
          </div>

         {projects.length == 0 ? (
            <div>There is no projects yes</div>
          ) : (
            //  <div>yesssssssssssssssssssss</div>
            projects.map((project,indexMap) => {
              const { id, link, logo, title, brief } = project;
              console.log(indexMap);
              return (
                <div className="col-md-4" key={id} data-aos="zoom-out" data-aos-delay={`${indexMap}00`} >
                  <div className="boxProject" >
                    <div className="PView">
                      <img src={logo} alt="" />
                    </div>
                    <div className="PText">
                      <a href={link} target="_blank">
                        {title}
                      </a>
                      <span>{brief}</span>
                    </div>
                    {
                      curruntUser !== null && <div>
                    <Button variant="primary" onClick={() => handlerEdit(project)} >edit</Button>{' '}
                    <Button variant="danger" onClick={() => deleteProjectFun(id)}>delete</Button>
                      </div>
                    }

                  </div>
                </div>
              );
            })
          )} 
        </div>
        {
            EditModal && <EditProject EditData={EditData} setEditModal={setEditModal} />
          }
          <h2>Internal systems</h2>
          <p>&#10053; General Authority for Military Industries (GAMI)</p> 
          <p>&#10053; General Authority of Foreign Trade (GAFT) </p>
          <p>&#10053; National Company of Telecommunications and Information Security (NTIS)</p>
      </div>
    </div>
  );
};

export default Projects;
