import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Alert from "react-bootstrap/Alert";
import "./experence.css";
import AddExperence from "./AddExperence";
import { Modal, Button, Form } from "react-bootstrap";
import { deleteData, deleteExpe } from "../../redux/slices/experienceSlice";
import {getData} from "../../redux/slices/experienceSlice";
import EditExprerence from "./EditExprerence";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../helper/firebaseConfig";

const Experence = ({curruntUser}) => {

  const [EditModal, setEditModal] = useState(false)
  const [EditData, setEditData] = useState({})
  const state = useSelector((state) => state.experienceReducer.experience);
  const dispatch = useDispatch();
  const handlerEdit = (data) => {
    setEditModal(prv => !prv)
    setEditData(data)
  }
  const deleteHandler = async (id) => {
    await deleteDoc(doc(db, "experience", id));
    await dispatch(deleteExpe(id))
    // await deleteData(id)
  }
  useEffect(() => {
    
    dispatch(getData())
  
  }, [getData])
  

  return (
    <div className="expr" id="Experience">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2>Experience</h2>
            {
               curruntUser !== null && <AddExperence />
            }
          </div>
           <div className="col-md-12">
            {state.length == 0 ? (
              <Alert variant="danger">There is no data</Alert>
            ) : (
              state.map((exper) => {
                const { id, nameCompany, date, description } = exper;
                // console.log(exper)
                return (
                  <div key={id} data-aos="fade-up">
                    <h3>
                      {nameCompany}
                      <span> {date}</span>{" "}
                    </h3>
                    <p>{description}</p>
                    {
                      curruntUser !== null && <div>
                        <Button variant="primary" onClick={() => handlerEdit(exper)}>edit</Button>{' '}
                        <Button variant="danger" onClick={() => deleteHandler(id)}>delete</Button>
                         </div>
                    }
                    

                    
                  </div>
                );
              })
            )}
          </div> 
          {/* start edit component */}
          {
            EditModal && <EditExprerence EditData={EditData} setEditModal={setEditModal} />
          }
          {/* end edit component */}
        </div>
      </div>
    </div>
  );
};

export default Experence;
